<template>
  <div id="app">
    <UnderConstruction/>
  </div>
</template>

<script>
import UnderConstruction from './components/UnderConstruction.vue'

export default {
  name: 'App',
  components: {
    UnderConstruction
  },
  mounted() {
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      this.onResize();
    });
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDocumentHeightVariable();
    },
    setDocumentHeightVariable() {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`,
      );
    },
  }

}
</script>

